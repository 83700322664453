import { FunctionalComponent, h, Fragment } from "preact";
import { route, Route, Router, RouterOnChangeArgs } from "preact-router";
import { useEffect, useState } from "preact/hooks";
import Protected from "./Protected";
import Workspace from "../../routes/workspace";
import Kapp from "../../routes/kapp";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { LoadingProgressStatus } from "../../models/loading-progress-enum";
import Portals from "../../routes/portals";
import PortalDetails from "../../routes/portalDetails";
import PersonasHome from "../../routes/personas";
import Analytics from "../../routes/analytics";
import SettingsPage from "../../routes/settingsPage";
import QuestionsPage from "../../routes/kapp/components/top-wrapper/SampleQuestions";
import AiTrainig from "../../routes/aiTraining";
import SharepointCallback from "../../routes/sharepoint-callback";
import DropboxCallback from "../../routes/dropbox-callback";
import SegmentationPage from "../../routes/segmentation";
import ChatbotDetails from "../../routes/chatbotDetails";
import Notfound from "../../routes/notfound";


const AppRouter: FunctionalComponent = ({ children }) => {
  const {
    isAuth,
    userDetails,
    loadingStatus,
    setLoadingStatus,
    logOutFromEditor,
  } = useUserAuthContext();

  const [currentRoute, setCurrentRoute] = useState<string | undefined>(
    undefined
  );
  const [prevRoute, setPrevRoute] = useState<string | undefined>(undefined);

  const handleRoute = (args: RouterOnChangeArgs) => {
    if (args.url.includes("flow=paid")) {
      localStorage.setItem("flow", "paid");
    }

    setCurrentRoute(args.url);
    setPrevRoute(args.previous);

    // //login logout route
    if (args.url === "/login") {
      if (
        !isAuth &&
        LoadingProgressStatus.GOOGLE_AUTH_INITILIZED === loadingStatus
      ) {
        //we are in fresh login page remove loading overlay
        setLoadingStatus(LoadingProgressStatus.DONE);
      } else if (isAuth === true) {
        //login page is not dealing with authorized users at the moment
        //we will logout
        logOutFromEditor();
      }
    }
  };

  useEffect(() => {
    if (isAuth === true) {
      // currentRoute.includes("?_ga=") if for google analiticts from korra.ai website
      //TODO: think of better solution
      if (
        currentRoute === "/" ||
        currentRoute.includes("?_ga=") ||
        currentRoute.includes("?_gl=") ||
        currentRoute.includes("?utm_source") ||
        currentRoute?.startsWith("/?")
      ) {
        const workspaceId = userDetails.workspaceId;
        route("/workspace/" + workspaceId + "/portals");
      } else {
        route(currentRoute);
      }
    }

    if (isAuth === false) {
      logOutFromEditor();
    }
  }, [isAuth, userDetails?.workspaceId]);

  return (
    <Fragment>
      <Router onChange={handleRoute}>
        <Protected path={"/"} component={Workspace} />
        <Protected path={"/workspace/:workspaceId"} component={Workspace} />

        <Protected
          path={"/workspace/:workspaceId/kapp/:kappId"}
          component={Kapp}
        />

        <Route path={"/callback/dropbox"} component={DropboxCallback} />

        <Route path={"/callback/sharepoint"} component={SharepointCallback} />
        {/* <Route path={"/workspace/personas"} component={PersonasHome} /> */}
        <Protected
          path={"/workspace/:workspaceId/personas"}
          component={PersonasHome}
        />
        <Protected
          path={"/workspace/:workspaceId/portals"}
          component={Portals}
        />
        <Protected
          path={"/workspace/:workspaceId/portals/:portalId"}
          component={PortalDetails}
        />
        <Protected
          path={"/workspace/:workspaceId/chatbot/:portalId"}
          component={ChatbotDetails}
        />
        <Protected
          path={"/stats/workspace/:workspaceId"}
          component={Analytics}
        />
        <Protected
          path={"/workspace/:workspaceId/settings"}
          component={SettingsPage}
        />
        <Protected
          path={"/workspace/:workspaceId/kapp/:kappId/sample-questions"}
          component={QuestionsPage}
        />
        <Protected
          path={"/workspace/:workspaceId/ai-training"}
          component={AiTrainig}
        />

        <Protected
          path={"/workspace/:workspaceId/segmentation"}
          component={SegmentationPage}
        />
        
        <Notfound default />
      </Router>
    </Fragment>
  );
};

export default AppRouter;
