import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { IUserWorkspace } from "../models/user-workspace";

import { creatNewUser, getUserByEmail } from "../api/user-api";
import { IUser } from "../models/user";
import { getUserWorkspace } from "../api/user-workspace-api";
import { creatNewWorkspace } from "../api/workspace-api";
import { IWorkspace } from "../models/workspace";
import { LoadingProgressStatus } from "../models/loading-progress-enum";
import { useSnackBar } from "./SnackBarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { getAuth0Audience } from "../configs/env";
import { getBillingUrl } from "../api/payments-api";
import {postOnboardingCompletion} from "../api/onboarding-api";

export interface IGoogleAuth {
  isKorraUser: boolean; //temporary
  isAuth: boolean;
  billingUrl?: string;
  loginToEditor: () => void;
  logOutFromEditor: (errMessage?: string) => void;
  userDetails: IUserWorkspace | null;
  setUserDetails: (user: IUserWorkspace) => void;
  loadingStatus: LoadingProgressStatus;
  setLoadingStatus: (status: LoadingProgressStatus) => void;
  isFirstLogin: boolean;
  setIsFirstLogin: (bool: boolean) => void;
}

export const UserAuthContext = createContext<IGoogleAuth>(null);

export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};

/**
 * a provider that impleents the auth
   upon login/sign up, (getemail/createuser call)
 *      - client: we get from client-googleAuth a token,  (which means that this is a real google user)
 *      - server: this token is verified
 *      -          return a .secret-encrypted (JWT) token with user_id(1)
 *      - client: get-user-company with user_id(1)
 *      - server: verifies 
 *                returns new token(2) with user_id(1) and company_id
 *      - client: uses token(2) in all subsequent calls and routes
 *        
 * @param param0
 *  * @returns
 */
const UserAuthProvider: FunctionalComponent = ({ children }) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [billingUrl, setBillingUrl] = useState<string>();
  const [isIdToken, setIsIdToken] = useState<boolean | null>(null);

  const [userDetails, setUserDetails] = useState<IUserWorkspace | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingProgressStatus>(
    LoadingProgressStatus.INIT
  );

  const [isKorraUser, setIsKorraUser] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [idToken, setIdToken] = useState<string | null>(null);

  const { showSnackBar } = useSnackBar();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const { id_token } = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${getAuth0Audience()}/api/v2/`,
            scope: "read:users",
          },
          detailedResponse: true,
        });

        setIdToken(id_token);
        setIsIdToken(true);
      } catch (e) {
        const err = e as { error: string };
        const path = window.location.pathname;

        if (err?.error && err?.error === "consent_required" ) {

          const queryParams = new URLSearchParams(window.location.search);
          if (path === "/onboarding" && queryParams.get("error")) {
            //showSnackBar("Consent Required", "error");
            window.location.href = "https://korra.ai/";
            logOutFromEditor("Consent Required");
          } else {
            logOutFromEditor("Consent Required");
          }
          return;
        }

        setIsIdToken(false);
      }
    };

    if (!isIdToken) {
      getIdToken();
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetch = async () => {
      await loginWithRedirect();
    };

    if (isIdToken === false) {
      fetch();
    }
  }, [isIdToken]);

  useEffect(() => {
    const getUserFromEditor = async () => {
      const email = user.email;
      const name = user.name;
      const firstName = user.given_name;
      const lastName = user.family_name;
      setIsKorraUser(false);

      let currentUser: IUser;
      let workspace: IWorkspace;
      let userWorkspace: IUserWorkspace;
      let isUserWorkspaceAvailble: boolean | null = null;

      if (localStorage?.getItem("first_login")) {
        setIsFirstLogin(true);
      }

      try {
        currentUser = await getUserByEmail(email, idToken);

        let sendOnboardingCompletion = false;
        if (!currentUser) {
          //user return null from server NOT as error
          //create new user and get a token created at the server
          currentUser = await creatNewUser(
            firstName,
            lastName,
            name,
            email,
            idToken
          );

          sendOnboardingCompletion = true;
        }

        //we got our user id lets try to get workspaceId from userWorkspace
        userWorkspace = await getUserWorkspace(
          currentUser.id,
          currentUser.userIdToken
        );

        if (sendOnboardingCompletion) {
          await postOnboardingCompletion(
            user.sub,
            idToken,
            {
              first_name: userWorkspace.userFirstName,
              last_name: userWorkspace.userLastName,
              email: email
            }
          )
        }

        setIsKorraUser(userWorkspace.isKorraUser); //is korra user from server

        try {
          const billing = await getBillingUrl(
            userWorkspace.workspaceId,
            currentUser.id,
            currentUser.userIdToken
          );

          setBillingUrl(billing.billing_url);
        } catch (e) {
          console.error(e);
        }

        if (!userWorkspace?.userId) {
          isUserWorkspaceAvailble = false;
        }
        //check if companyUser collection have the company and ready
        if (userWorkspace?.workspaceId && userWorkspace?.workspaceName) {
          isUserWorkspaceAvailble = true;
          //continue with workspaceId
          setUserDetails(userWorkspace);
        } else {
          // we add new company
          // with the "Knowledge space name" as the initial workspace name at the moment
          workspace = await creatNewWorkspace(
            `${currentUser.firstName}'s space`,
            currentUser.id,
            currentUser.userIdToken
          );

          if (!isUserWorkspaceAvailble) {
            //userWorkspace is not full yet
            // or event stuck in userCreated || companyCreated or both
            userWorkspace = {
              userId: currentUser.id,
              userFirstName: currentUser.firstName,
              userLastName: currentUser.lastName,
              userName: currentUser.name,
              userEmail: currentUser.email,
              workspaceId: workspace.id,
              workspaceName: workspace.name,
              // note: workspace token
              token: workspace.token,
            };

            setIsFirstLogin(true);
          }

          setUserDetails(userWorkspace);
        }
        setLoadingStatus(LoadingProgressStatus.USER_COMPANY_LOADED);
        setIsAuth(true);
      } catch (err) {
        const message = err.toString() ? err.toString() : "failed to login";

        logOutFromEditor(message);
      }
    };

    if (idToken && user) {
      getUserFromEditor();
    }
  }, [idToken, user]);

  const logOutFromEditor = (errMessage?: string) => {
    const setNullAndLogout = () => {
      localStorage.removeItem("onboading_paid_flow_shown")

      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/`,
        }
      });

      //setIdToken(null);
      //setIsIdToken(false);
    };
    if (errMessage) {
      // show the error and logout after 10 seconds
      showSnackBar(errMessage, "error");
      setTimeout(() => {
        setNullAndLogout();
      }, 10000);
    } else {
      setNullAndLogout();
    }
  };

  const loginToEditor = async () => {
    return await loginWithRedirect();
  };

  const auth: IGoogleAuth = {
    isKorraUser, //temporary
    billingUrl,
    isAuth,
    loginToEditor,
    logOutFromEditor,
    userDetails,
    setUserDetails,
    loadingStatus,
    setLoadingStatus,
    isFirstLogin,
    setIsFirstLogin,
  };

  return (
    <UserAuthContext.Provider value={auth}>{children}</UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
